import { stripUnit } from 'polished'
import { animationTimes } from 'styles/variables'

const transitionDelay = stripUnit(animationTimes.pageTransition)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const savedPosition = getSavedScrollPosition(location)
  window.setTimeout(
    () => window.scrollTo(...(savedPosition || [0, 0])),
    transitionDelay * 2
  )

  return false
}
