import { createGlobalStyle } from 'styled-components'

import Normalize from './normalize'
import Typography from './typography'
import Vendor from './vendor'
import { animationTimes, animationCurve } from 'styles/variables'

export const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${Typography};
  ${Vendor};

  body {
    background-color: ${({ theme }) => theme.background};
    transition: background-color ${
      animationTimes.pageTransition
    } ${animationCurve};
  }
`
