import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Popover } from '@headlessui/react'

import { animationTimes, animationCurve } from 'styles/variables'
import * as spacing from 'styles/spacing'
import { primaryColor } from 'styles/colors'

import Dropdown from './Dropdown'

import Chevron from 'assets/icons/chevron-down.svg'
import Icon from 'components/Icon'

const Container = styled(Link)`
  position: relative;
  transition: color ${animationTimes.base} ${animationCurve};
  cursor: pointer;
  color: ${({ theme }) => theme.body};

  &:hover,
  &${'.active'} {
    color: ${({ theme }) => theme.heading};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${({ theme }) => theme.heading};

      ${Label}:after {
        opacity: 1;
        transform: none;
      }
    `}
`

const Label = styled.span`
  position: relative;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: ${spacing.tiny};
    transform: scaleX(0);
    transform-origin: left;
    right: 0;
    height: 2px;
    background-color: ${primaryColor};
    transition: opacity ${animationTimes.base} ${animationCurve},
      transform ${animationTimes.base} ${animationCurve};
    opacity: 0;
  }

  ${Container}${'.active'} &:after {
    opacity: 1;
    transform: none;
  }
`

const Trigger = styled.button`
  display: flex;
  align-items: center;
`

const IconWrap = styled.div`
  display: flex;
  opacity: 0.9;
  margin-right: -${spacing.tiny};
`

const NavItem = ({ link, sublinks, label, location }) => {
  if (sublinks)
    return (
      <Container
        as="div"
        active={
          sublinks.some((sublink) =>
            sublink.link.includes(location.pathname)
          ) && location.pathname !== '/'
        }
      >
        <Popover>
          {({ open, close }) => (
            <>
              <Trigger as={Popover.Button}>
                <Label>
                  {label}
                  <IconWrap>
                    <Icon icon={<Chevron />} />
                  </IconWrap>
                </Label>
              </Trigger>
              <Dropdown items={sublinks} isOpen={open} close={close} />
            </>
          )}
        </Popover>
      </Container>
    )

  return (
    <Container activeClassName="active" to={link} partiallyActive>
      <Label>{label}</Label>
    </Container>
  )
}

export default NavItem
