import { css } from 'styled-components'
import { stripUnit } from 'polished'

// Breakpoints
export const breakpoints = {
  xSmall: '25rem' /* 400px */,
  small: '37.5rem' /* 600px */,
  medium: '56.25rem' /* 900px */,
  large: '75rem' /* 1200px */,
  xLarge: '87.5rem' /* 1400px */,
}

export const largerThan = Object.entries(breakpoints).reduce(
  (acc, [label, size]) => ({
    ...acc,
    [label]: (...args) =>
      css`
        @media (min-width: ${size}) {
          ${css(...args)};
        }
      `,
  }),
  {}
)

export const smallerThan = Object.entries(breakpoints).reduce(
  (acc, [label, size]) => ({
    ...acc,
    [label]: (...args) =>
      css`
        @media (max-width: ${stripUnit(size) - 0.01 + 'rem'}) {
          ${css(...args)};
        }
      `,
  }),
  {}
)
