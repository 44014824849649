import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Popover } from '@headlessui/react'
import { motion, AnimatePresence } from 'framer-motion'
import { stripUnit, rgba } from 'polished'

import { animationTimes, animationCurve, borderRadius } from 'styles/variables'
import { black } from 'styles/colors'
import { square, cover } from 'styles/helpers'
import * as spacing from 'styles/spacing'

import Icon from 'components/Icon'

const DURATION = stripUnit(animationTimes.base)

const variants = {
  collapsed: {
    opacity: 0,
    y: -4,
    transition: { duration: DURATION },
  },
  open: {
    opacity: 1,
    y: 0,
    transition: { duration: DURATION },
  },
}

const Container = styled.div`
  position: absolute;
  top: 100%;
  margin-top: ${spacing.medium};
  left: 50%;
  padding: ${spacing.large};
  background-color: ${({ theme }) => theme.action};
  border: 1px solid ${({ theme }) => theme.border};
  z-index: 20;
  border-radius: ${borderRadius};
  width: 210px;
  margin-left: -105px;
  filter: drop-shadow(0 2px 4px ${rgba(black, 0.05)});

  &:before {
    content: '';
    position: absolute;
    top: -7px; /* Half size */
    left: 50%;
    transform: rotate(-45deg);
    background-color: ${({ theme }) => theme.action};
    border-top: 1px solid ${({ theme }) => theme.border};
    border-right: 1px solid ${({ theme }) => theme.border};
    ${square('12px')};
  }

  ${(props) =>
    props.wide &&
    css`
      width: 500px;
      margin-left: -250px;
    `}
`

const Inner = styled(Popover.Panel)`
  position: static;

  &:focus {
    outline: 0 !important;
  }
`

const List = styled.div`
  display: grid;
  grid-gap: ${spacing.scale(spacing.medium, 1.25)};

  ${(props) =>
    props.wide &&
    css`
      grid-template-columns: 1fr 1fr;
    `}
`

const Item = styled(Popover.Button)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.body};
  font-size: 0.9em;
  transition: color ${animationTimes.base} ${animationCurve};

  &:hover,
  &${'.active'} {
    color: ${({ theme }) => theme.heading};
  }
`

const IconWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing.medium};
  flex-shrink: 0;
  ${square('40px')};

  &:after {
    content: '';
    transition: background-color ${animationTimes.base} ${animationCurve};
    background-color: currentColor;
    opacity: 0.1;
    border-radius: ${borderRadius};
    ${cover('absolute')};
  }
`

const StyledIcon = styled(Icon)`
  position: relative;
  z-index: 1;
`

const Dropdown = ({ isOpen, items, close }) => (
  <AnimatePresence>
    {isOpen && (
      <Container
        as={motion.div}
        wide={items.length > 3}
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={variants}
        type="ease"
        transition={{ ease: animationCurve }}
      >
        <Inner static>
          <List wide={items.length > 3}>
            {items.map((item, i) => (
              <Item
                as={Link}
                key={i}
                onClick={close}
                to={item.link}
                role="menuitem"
                activeClassName="active"
              >
                {!!item.icon && (
                  <IconWrap>
                    <StyledIcon icon={item.icon} />
                  </IconWrap>
                )}
                {item.label}
              </Item>
            ))}
          </List>
        </Inner>
      </Container>
    )}
  </AnimatePresence>
)

export default Dropdown
