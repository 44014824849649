import React from 'react'
import styled, { css } from 'styled-components'

import { largerThan } from 'styles/media'
import { verticalPadding } from 'styles/helpers'
import { animationTimes, animationCurve } from 'styles/variables'

const Container = styled.section`
  display: block;
  background-color: ${({ theme }) => theme.background};
  transition: background-color ${animationTimes.pageTransition}
    ${animationCurve};
  ${verticalPadding`3rem`};

  ${largerThan.small`
    ${verticalPadding`4rem`};
  `};

  ${largerThan.medium`
    ${verticalPadding`6rem`};
  `};

  ${largerThan.large`
    ${verticalPadding`8rem`};
  `};

  ${largerThan.xLarge`
    ${verticalPadding`10rem`};
  `};

  ${(props) =>
    props.flushTop &&
    css`
      padding-top: 0 !important;
    `}

  ${(props) =>
    props.flushBottom &&
    css`
      padding-bottom: 0 !important;
    `}

  ${(props) =>
    props.hasBorder &&
    css`
      border-top: 1px solid ${({ theme }) => theme.border};
    `}
  
  ${(props) =>
    props.transparent &&
    css`
      background-color: transparent;
    `}
`

const Block = styled.div`
  position: relative;

  & + & {
    margin-top: 2rem;

    ${largerThan.small`
      margin-top: 2.75rem;
    `};

    ${largerThan.medium`
      margin-top: 3.5rem;
    `};

    ${largerThan.large`
      margin-top: 4.75rem;
    `};

    ${largerThan.xLarge`
      margin-top: 6rem;
    `};
  }
`

const Section = ({
  children,
  hasBorder,
  transparent,
  flushTop,
  flushBottom,
  id,
}) => (
  <Container
    hasBorder={hasBorder}
    id={id}
    flushTop={flushTop}
    flushBottom={flushBottom}
    transparent={transparent}
  >
    {children}
  </Container>
)

export const SectionBlock = ({ children }) => <Block>{children}</Block>

export default Section
