import React from 'react'
import styled, { css } from 'styled-components'

import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: ${spacing.large};
  padding-right: ${spacing.large};

  ${largerThan.small`
    padding-left: ${spacing.xxLarge};
    padding-right: ${spacing.xxLarge};
  `};

  ${largerThan.medium`
    padding-left: 5vw;
    padding-right: 5vw;
  `};
`

const Inner = styled.div`
  width: 100%;
  max-width: 1310px;
  margin: 0 auto;

  ${(props) =>
    props.size === 'narrow' &&
    css`
      max-width: 860px;
    `}
`

const Wrapper = ({ children, size }) => (
  <Container>
    <Inner size={size}>{children}</Inner>
  </Container>
)

export default Wrapper
