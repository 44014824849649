// Animation

export const animationTimes = {
  base: '0.3s',
  long: '0.5s',
  pageTransition: '300ms', // Leave this in ms since we refer to it in JS
}
export const animationCurve = 'ease-in-out'

// Border Radius
export const borderRadius = '0.5rem'

// Inputs
export const inputHeight = '3.5rem'
export const inputHeightMobile = '2.75rem'

// Nav
export const navHeightMobile = '64px'
export const navHeight = '100px'
