import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { mix, rgba, readableColor } from 'polished'

import { primaryColor, black, white } from 'styles/colors'
import {
  animationTimes,
  animationCurve,
  borderRadius,
  inputHeight,
  inputHeightMobile,
} from 'styles/variables'
import { fontWeights, fontSizes } from 'styles/typography'
import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'
import { boxShadow } from 'styles/helpers'

import Icon from 'components/Icon'

const Container = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizes.small};
  border-radius: ${borderRadius};
  padding: 0 ${spacing.medium};
  height: ${inputHeightMobile};
  cursor: pointer;
  font-weight: ${fontWeights.medium};
  text-align: center;
  transition: all ${animationTimes.base} ${animationCurve};
  border: 1px solid transparent;

  ${largerThan.medium`
    font-size: ${fontSizes.regular};
    padding: 0 ${spacing.large};
    height: ${inputHeight};
  `};

  ${(props) =>
    props.intent === 'primary' &&
    css`
      color: ${white};
      background-color: ${primaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.95, primaryColor, black)};
      }
    `}

  ${(props) =>
    props.intent === 'secondary' &&
    css`
      color: ${primaryColor};
      background-color: ${rgba(primaryColor, 0.15)};

      &:hover,
      &:focus,
      &:active {
        background-color: ${rgba(primaryColor, 0.2)};
      }
    `}
  
  ${(props) =>
    props.intent === 'tertiary' &&
    css`
      color: ${({ theme }) => theme.heading};
      background-color: ${({ theme }) => theme.action};
      border: 1px solid ${({ theme }) => theme.border};
      ${boxShadow.small};

      &:hover,
      &:focus,
      &:active {
        background-color: ${({ theme }) => theme.action};
        border-color: ${({ theme }) => mix(0.9, theme.border, theme.heading)};
        ${boxShadow.medium};
      }
    `}

  ${(props) =>
    props.intent === 'custom' &&
    css`
      color: ${readableColor(props.customcolor)};
      background-color: ${props.customcolor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, props.customcolor, black)};
      }
    `}
  
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${fontSizes.small} !important;
      padding: 0 ${spacing.scale(spacing.small, 1.5)};
      height: ${spacing.scale(inputHeightMobile, 0.85)};

      ${largerThan.medium`
        padding: 0 ${spacing.scale(spacing.medium, 1.25)};
        height: ${spacing.scale(inputHeight, 0.85)};
      `};
    `}
    
  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.35;
    user-select: none;
  }
`

const IconWrap = styled.div`
  margin-right: ${spacing.small};
`

const getElement = (props) => {
  if (props.to) return Link
  if (props.href) return 'a'
  return 'button'
}

const Button = ({
  children,
  name,
  intent = 'primary',
  size,
  icon,
  innerRef,
  customcolor,
  type = 'button',
  ...rest
}) => (
  <Container
    intent={intent}
    size={size}
    customcolor={customcolor}
    type={type}
    as={getElement(rest)}
    ref={innerRef}
    {...rest}
  >
    {icon && (
      <IconWrap>
        <Icon icon={icon} />
      </IconWrap>
    )}
    {children}
  </Container>
)

export default Button
