import React from 'react'
import styled from 'styled-components'

import { square } from 'styles/helpers'

const Container = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  ${square('24px')};

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`

const Icon = ({ icon }) => <Container>{icon}</Container>

export default Icon
