import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { stripUnit, mix } from 'polished'
import { motion, AnimatePresence } from 'framer-motion'

import { themes } from 'styles/theme'
import { black } from 'styles/colors'
import { GlobalStyle } from 'styles/global'
import { animationTimes, animationCurve } from 'styles/variables'

import Nav from 'components/Nav'
import MetaTags from 'components/MetaTags'
import Footer from 'components/Footer'

const duration = stripUnit(animationTimes.pageTransition) / 1000 // Framer wants seconds

const variants = {
  initial: {
    y: 5,
    opacity: 0,
    transition: { duration: duration },
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
}

const Page = styled.div`
  width: 100%;
  background-color: ${({ theme }) => mix(0.92, theme.background, black)};
`

const Inner = styled.div`
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.background};
`

const Layout = ({ children, location }) => {
  const [theme, setTheme] = useState(themes.light)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (location.pathname.includes('/industries')) {
        setTheme(themes.dark)
      } else setTheme(themes.light)
    }, duration / 2)

    return () => {
      clearTimeout(timeout)
    }
  }, [location.pathname, theme])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <MetaTags title="Custom software development in Montreal | Volume7" />
      <Page>
        <Inner>
          <Nav location={location} />
          <AnimatePresence mode="wait">
            <motion.main
              key={location.pathname}
              variants={variants}
              initial="initial"
              animate="enter"
              exit="initial"
              type="ease"
              transition={{ ease: animationCurve }}
            >
              {children}
              <Footer />
            </motion.main>
          </AnimatePresence>
        </Inner>
      </Page>
    </ThemeProvider>
  )
}

export default Layout
