import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { stripUnit, rgba } from 'polished'
import { Popover, Portal } from '@headlessui/react'
import { motion, AnimatePresence } from 'framer-motion'

import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'
import { animationCurve, animationTimes } from 'styles/variables'
import { cover, boxShadow, square } from 'styles/helpers'
import { black } from 'styles/colors'

import { INDUSTRIES, SERVICES } from 'utils/constants'

import Icon from 'components/Icon'
import MobileNavItem from './MobileNavItem'

import HamburgerIcon from 'assets/icons/hamburger.svg'
import CloseIcon from 'assets/icons/close.svg'

const DURATION = stripUnit(animationTimes.base)

const menuVariants = {
  initial: {
    x: 0,
    transition: { duration: DURATION },
  },
  enter: { x: '-100%', transition: { duration: DURATION } },
}

const backdropVariants = {
  initial: {
    opacity: 0,
    transition: { duration: DURATION },
  },
  enter: { opacity: 1, transition: { duration: DURATION } },
}

const Container = styled.div`
  display: flex;

  ${largerThan.medium`
    display: none;
  `};
`

const Toggle = styled.button`
  position: fixed;
  top: 22px;
  z-index: 400;
  right: ${spacing.large};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.heading};
  background-color: ${({ theme }) => theme.background};
  border: 1px solid transparent;
  border-radius: 50%;
  backdrop-filter: blur(6px);
  transition: background-color ${animationTimes.pageTransition}
      ${animationCurve},
    border-color ${animationTimes.pageTransition} ${animationCurve},
    box-shadow ${animationTimes.pageTransition} ${animationCurve};
  will-change: background-color, box-shadow, border-color;
  ${square('40px')};

  ${(props) =>
    props.scrolled &&
    css`
      background-color: ${({ theme }) => rgba(theme.action, 0.8)};
      border-color: ${({ theme }) => theme.border};
      ${boxShadow.small};
    `}

  ${largerThan.small`
    top: 31px;
    right: ${spacing.xxLarge};
  `};
`

const Menu = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.background};
  z-index: 800;
  display: block;
  width: 90%;
  max-width: 400px;
  overflow-y: auto;
  ${boxShadow.large};

  ${largerThan.medium`
    display: none;
  `};
`

const Backdrop = styled(motion.div)`
  ${cover('fixed')};
  background-color: ${rgba(black, 0.6)};
  z-index: 700;

  ${largerThan.medium`
    display: none;
  `};
`

const Inner = styled(Popover.Panel)`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${spacing.xLarge};

  ${largerThan.small`
    padding: ${spacing.xxLarge};
  `};
`

const Close = styled.button`
  display: flex;
  position: absolute;
  top: ${spacing.large};
  right: ${spacing.large};
  color: ${({ theme }) => theme.heading};
  padding: ${spacing.small};
  margin: -${spacing.small};
`

const List = styled.div`
  padding-right: ${spacing.xLarge};
  margin-top: -${spacing.scale(spacing.tiny, 1.5)};
  margin-bottom: -${spacing.scale(spacing.tiny, 1.5)};

  ${largerThan.small`
    margin-top: -${spacing.small};
    margin-bottom: -${spacing.small};
  `};
`

const MobileNav = ({ location, scrolled }) => (
  <Container>
    <Popover as={Fragment}>
      {({ open }) => (
        <>
          <Toggle
            as={Popover.Button}
            scrolled={scrolled}
            aria-label="Open site menu"
          >
            <Icon icon={<HamburgerIcon />} />
          </Toggle>
          <Portal>
            {/* Menu */}
            <AnimatePresence>
              {open && (
                <Menu
                  variants={menuVariants}
                  initial="initial"
                  animate="enter"
                  exit="initial"
                  type="ease"
                  transition={{ ease: animationCurve }}
                >
                  <Inner static>
                    <Close as={Popover.Button} aria-label="Close site menu">
                      <Icon icon={<CloseIcon />} />
                    </Close>
                    <List>
                      <MobileNavItem
                        location={location}
                        label="Work"
                        link="/work/"
                      />
                      <MobileNavItem
                        location={location}
                        label="Services"
                        link="/services/"
                        sublinks={SERVICES.map((services, i) => {
                          return {
                            key: i,
                            label: services.name,
                            link: `/services/${services.slug}/`,
                            icon: services.icon,
                          }
                        })}
                      />
                      {/* <MobileNavItem
                        location={location}
                        label="Industries"
                        link="/industries/"
                        sublinks={INDUSTRIES.map((industry, i) => {
                          return {
                            key: i,
                            label: industry.name,
                            link: `/industries/${industry.slug}/`,
                            icon: industry.icon,
                          }
                        })}
                      /> */}
                      <MobileNavItem
                        location={location}
                        label="About"
                        link="/about/"
                      />
                      <MobileNavItem
                        location={location}
                        label="Careers"
                        link="/careers/"
                      />
                      <MobileNavItem
                        location={location}
                        label="Contact"
                        link="/contact/"
                      />
                    </List>
                  </Inner>
                </Menu>
              )}
            </AnimatePresence>

            {/* Background */}
            <AnimatePresence>
              {open && (
                <Backdrop
                  key="backdrop"
                  variants={backdropVariants}
                  initial="initial"
                  animate="enter"
                  exit="initial"
                  type="ease"
                  transition={{ ease: animationCurve }}
                />
              )}
            </AnimatePresence>
          </Portal>
        </>
      )}
    </Popover>
  </Container>
)

export default MobileNav
